<template>
  <v-navigation-drawer
    v-model="getThemeMode.verticalSidebarDrawer"
    class="sidebar-six primary"
    light
    height="100%"
    width="280px"
    :mobile-breakpoint="960"
    :floating="true"
    app
  >
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="ps"
      style="height: 100%"
    >
      <div class="px-8 py-4 top-toolbar">
        <v-img src="@/assets/images/logos/PositiveMedia-white.png" class="flex-grow-0" contain />
      </div>

      <v-divider class="mb-5"></v-divider>

      <v-list class="py-0 mx-4 sidebar-lists" dense>
        <template v-for="(item, i) in items">
          <div :key="item.subheader" v-if="item.subheader">
            <v-subheader>
              {{ item.subheader }}
            </v-subheader>

            <v-divider></v-divider>
          </div>

          <base-item-group-two
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group-two>

          <base-item-two
            v-else-if="!item.subheader"
            :key="`item-${i}`"
            :item="item"
          />
        </template>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import menuItems from "@/constants/sidebar-menu";

export default {
  name: "SidebarLayout",
  data: (vm) => ({
    selectedItem: 0,
    group: null,
    items: menuItems
  }),
  computed: {
    ...mapGetters(["getThemeMode", "userAccessURls"]),
    bg() {
      return this.background
        ? "https://images.pexels.com/photos/1687678/pexels-photo-1687678.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
        : undefined;
    },
    getMiniVariant() {
      return this.miniVariant;
    },
  },
  watch: {
    userAccessURls(newVal) {
      this.accessUpdate(newVal);
    }
  },
  created() {
    this.accessUpdate(this.userAccessURls);
  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer"]),
    toggleSidebar() {
      this.changeVerticalSidebarMini();
      this.expandOnHover = !this.expandOnHover;
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },
    accessUpdate(userAccess) {
      if (userAccess.length === 0) {
        this.items = menuItems.filter(item => item.title === 'Info');
      } else {
        this.items = menuItems.filter(item => {
          if (item.children) {
            item.children = item.children.filter(child => child.access === '' || userAccess.indexOf(child.access) !== -1);
            return  item.children.length;
          }
          return item.access === '' || userAccess.indexOf(item.access) !== -1;
        });
      }
    }
  },
};
</script>
<style lang="scss">
.sidebar-six {
  .v-list-item__icon {
    margin-right: 8px !important;
  }

  .v-list-item--link:before {
    border-radius: 8px;
  }
}
</style>
